import { notify } from '@kyvg/vue3-notification';

export default {
  data() {
    return {
      text: '',
      options: {
        duration: 3000,
        speed: 500,
        ignoreDuplicates: false,
        closeOnClick: true,
      },
    };
  },
  methods: {
    showSuccessMessage(text) {
      this.text = this.$t(text);

      notify({
        type: 'success',
        text: `${this.text}`,
        title: `${this.$t('default.label.success')}`,
        ...this.options,
      });
    },
    showErrorMessage(text) {
      this.text = this.$t(text);

      notify({
        type: 'error',
        text: `${this.text}`,
        title: `${this.$t('default.label.error')}`,
        ...this.options,
        duration: -1,
      });
    },
    showInfoMessage(text) {
      this.text = this.$t(text);
      notify({
        text: `${this.text}`,
        title: `${this.$t('default.label.info')}`,
        ...this.options,
      });
    },
    showWarningMessage(text) {
      this.text = this.$t(text);
      notify({
        type: 'warn',
        text: `${this.text}`,
        title: `${this.$t('default.label.warning')}`,
        ...this.options,
      });
    },
    snackbarType(item) {
      if (item && item.type) {
        return `rbx-notification rbx-notification__${item.type}`;
      }

      return 'rbx-notification rbx-notification__info';
    },
    copySnackBarText() {
      const snackbar = document.getElementById('snackbar');

      if (snackbar) {
        navigator.clipboard.writeText(snackbar.textContent);
      }
    },
  },
};
