import axios from 'axios';

export default {
  getUsers({ commit }, params) {
    commit('setLoading', true);

    return axios.get('/user/all', { params: { deleted: params } })
      .then(({ data }) => {
        commit('setUserList', data);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  updateUser(_, data) {
    return axios.post('/user', data.item);
  },
  deleteUser(_, id) {
    return axios.delete('/user', { params: { id } });
  },
  editUser({ commit }, item) {
    commit('setEditedUser', { ...item });
  },
};
