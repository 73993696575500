export default {
  setUserList(state, data) {
    state.userList = data;
  },
  setEditedUser(state, item) {
    state.editedUser = item;
  },
  setLoginUser(state, item) {
    state.loginUser = item;
  },
  setLoading(state, value) {
    state.isLoading = value;
  },
};
