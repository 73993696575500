export default {
  setLoading(state, value) {
    state.isLoading = value;
  },
  setCompanyData(state, data) {
    state.companyData = data;
  },
  setError(state, value) {
    if (!value) {
      state.errorMessage = '';

      return;
    }

    if (value?.response?.status === 404) {
      state.errorMessage = 'error.order.not.found';
    } else {
      state.errorMessage = 'server.error';
    }
  },
};
