function checkToken(to, from, next) {
  if (window.localStorage.getItem('session_id')) {
    next();
  } else {
    next({ path: '/admin' });
  }
}

export default [
  {
    path: '/',
    name: 'returnForm',
    component: () => import('../modules/returnForm/index.vue'),
  },
  {
    path: '/admin',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/modules/company/index.vue'),
    beforeEnter: checkToken,
  },
  {
    path: '/adminpanel',
    name: 'AdminPanel',
    component: () => import('@/modules/adminPanel/index.vue'),
    beforeEnter: checkToken,
    children: [
      {
        path: '/userList',
        name: 'userList',
        component: () => import('@/modules/userList/index.vue'),
      },
      {
        path: '/companyList',
        name: 'companyList',
        component: () => import('@/modules/companyList/index.vue'),
      },
      {
        path: '/localization',
        name: 'localization',
        component: () => import('@/modules/manageTranslations/index.vue'),
      },
    ],

  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import('@/views/Error404.vue'),
  },
  {
    path: '/error401',
    name: 'Error401',
    component: () => import('@/views/Error401.vue'),
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];
