import axios from 'axios';
import router from '../router/index.js';

export const normalizeResponse = (acc) => {
  const { data, meta } = acc.data;

  if (meta) {
    acc.data = data;
    acc.meta = meta;
  }

  return Promise.resolve(acc);
};
(() => {
  axios.interceptors.response.use(normalizeResponse, (err) => {
    if (
      err?.response?.data === 'authenticate.user.session.expired'
      && window.location.pathname !== '/admin'
      && window.location.pathname !== '/'
    ) {
      router.push('/admin');
      localStorage.removeItem('session_id');
    }

    return Promise.reject(err);
  });
})();
