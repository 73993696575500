import { $t } from '@/translations/index.js';

export default {
  headers() {
    return [
      { title: `${$t('tenant.id')}`, key: 'tenant_id' },
      { title: `${$t('name')}`, key: 'name' },
      { title: `${$t('username')}`, key: 'username' },
      { title: `${$t('company.name')}`, key: 'name' },
      { title: 'Admin', key: 'isAdmin' },
      { title: 'Master', key: 'isMaster' },
      {
        title: `${$t('actions')}`, key: 'actions', sortable: false, width: '10%',
      },
    ];
  },
};
