import { initState } from './state.js';

export default {
  setLoading(state, value) {
    state.isLoading = value;
  },
  setCompanyData(state, data) {
    state.companyData = data;
  },
  resetState(state) {
    Object.assign(state, initState);
  },
};
