import axios from 'axios';

export default {
  getCompanies({ commit }, params) {
    commit('setLoading', true);

    return axios.get('/company/all', { params: { deleted: params } })
      .then(({ data }) => {
        commit('setCompanyList', data);

        return data;
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  updateCompany(_, data) {
    return axios.post('/company', data.item);
  },
  deleteCompany(_, id) {
    return axios.delete('/company', { params: { id } });
  },
};
