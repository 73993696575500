import axios from 'axios';

export default {
  getCompany({ commit }, id = '') {
    commit('setLoading', true);

    return axios.get('/company', { params: { id } })
      .then(({ data }) => {
        commit('setCompanyData', data);

        return data;
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  updateCompany(_, data) {
    return axios.post(`/company/${data.type}`, data.item);
  },
  deleteItem(_, data) {
    return axios.delete(`/company/${data.type}`, { data: data.item });
  },
  renewToken(_, payload) {
    return axios.post('/company/renewToken', payload);
  },
  resetState({ commit }) {
    commit('resetState');
  },
  testMailConnection(_, nodeMailerConfig) {
    return axios.post('/mail/test', nodeMailerConfig);
  },
  getReybexReturnReasons(_, companyId) {
    return axios.get(`/company/reybexReturnReasons?company_id=${companyId}`);
  },
  postFile(_, payload) {
    return axios.post('/company/file', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
