import { $t } from '@/translations/index.js';

export default {
  headers() {
    return [
      { title: `${$t('code')}`, key: 'code' },
      { title: `${$t('default')}`, key: 'default' },
      { title: `${$t('english')}`, key: 'en' },
      { title: `${$t('turkish')}`, key: 'tr' },
      { title: `${$t('german')}`, key: 'de' },
      { title: `${$t('actions')}`, key: 'actions', sortable: false },
    ];
  },
};
