import axios from 'axios';
import { setLocalization } from '@/translations/index.js';

export default {
  login({ commit, dispatch }, payload) {
    return axios.post('/login', payload)
      .then(({ data }) => {
        commit('setToken', data.session_id);

        return dispatch('getLoginUser');
      });
  },
  logoutUser({ commit }) {
    return axios.post('/logout')
      .then(() => {
        commit('setLoggedUser', {});
        commit('userList/setUserList', [], { root: true });
      });
  },
  getLoginUser({ commit, dispatch }) {
    return axios.get('/user')
      .then(({ data }) => {
        commit('setLoggedUser', data);
        dispatch('updateLocalization', { companyId: data.company_id });
      });
  },
  updateLocalization({ getters }, locale) {
    if (typeof locale !== 'object') {
      return setLocalization(locale, getters.company_id);
    }

    return setLocalization(locale.locale, locale.companyId);
  },
};
