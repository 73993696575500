import { $t } from '@/translations/index.js';

export default {
  headers() {
    return [
      {
        title: `${$t('tenant.id')}`,
        key: 'tenant_id',
        type: 'text',
        width: '15%',
      },
      {
        title: `${$t('icon')}`,
        key: 'icon_url',
        type: 'text',
        sortable: false,
        width: '10%',
      },
      {
        title: `${$t('name')}`,
        key: 'name',
        type: 'text',
      },
      {
        title: 'Premium',
        key: 'isPremium',
      },
      {
        title: `${$t('actions')}`,
        key: 'actions',
        sortable: false,
      },
    ];
  },
};
