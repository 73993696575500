import { createStore } from 'vuex';
import app from '../modules/app/store/index.js';
import company from '../modules/company/store/index.js';
import companyList from '../modules/companyList/store/index.js';
import userList from '../modules/userList/store/index.js';
import returnForm from '../modules/returnForm/store/index.js';
import manageTranslations from '../modules/manageTranslations/store/index.js';

export default createStore({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    app,
    company,
    companyList,
    userList,
    returnForm,
    manageTranslations,
  },
});
