import axios from 'axios';
import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import { i18n, setLocalization } from '@/translations/index.js';
import * as Sentry from '@sentry/vue';
import App from './modules/app/index.vue';
import router from './router/index.js';
import store from './store/index.js';
import vuetify from './plugins/vuetify.js';
import './utils/tokenInterceptor.js';
import './utils/responseInterceptor.js';

const app = createApp(App);

axios.defaults.baseURL = import.meta.env.VITE_API_ENDPOINT;

app.config.productionTip = false;
setLocalization();
app.use(i18n);
app.use(Notifications);
app.use(store);
app.use(router);
app.use(vuetify);

Sentry.init({
  app,
  enabled: import.meta.env.MODE !== 'development',
  dsn: 'https://c90091f85e3dbb46594f6556887804c3@o4505476423811072.ingest.sentry.io/4506774012231680',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.MODE,
  trackComponents: true,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.mount('#app');
