import 'vuetify/styles';
import { createVuetify } from 'vuetify';

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          error: '#FF5252',
        },
      },
      dark: {
        colors: {
          error: '#FF5252',
        },
      },
    },
  },
  defaults: {
    VSwitch: {
      color: 'primary',
      hideDetails: true,
    },
  },
});
