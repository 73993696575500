export default {
  isMaster(state) {
    return state.loggedUser.isMaster;
  },
  isAdmin(state) {
    return state.loggedUser.isAdmin;
  },
  company_id(state) {
    return state.loggedUser.company_id;
  },
};
