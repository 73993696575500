import axios from 'axios';
import router from '@/router/index.js';

export default {
  getOrderData({ commit }, params) {
    commit('setLoading', true);
    commit('setError', '');

    return axios.get('/salesHead/getOrderData', { params })
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },

  getCompanyData({ commit }, companyName) {
    commit('setLoading', true);

    return axios.get('/company', { params: { companyName } })
      .then(({ data }) => {
        commit('setCompanyData', data);
      })
      .catch(() => {
        router.push({ name: 'Error404' });
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },

  finishRefund({ commit }, orderDetails) {
    commit('setLoading', true);
    commit('setError', '');

    return axios.post('/salesHead/return', orderDetails)
      .catch((err) => {
        commit('setError', err);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
};
